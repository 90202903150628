<template>
<div></div>
</template>

<script>

// 暴露出这些属性
export default {
    // httpUrl :"http://127.0.0.1:30000/",
    httpUrl :"https://kob.sealks.cn/",
    // wsUrl:"ws://127.0.0.1:30000/",
    wsUrl:"wss://kob.sealks.cn/",
    
}
</script>

<style scoped></style>